import {
  ComponentOptions,
  Rule,
  WrapperData,
} from "@agile/common-types/build/configurableSp/types";
import { InlineSelectInput } from "@agile/react-components";
import moment from 'moment-business-days';
import { Dispatch, SetStateAction } from "react";
import { isDefined } from "validate.js";
import FirstObservationIn from "../../pages/structured-products/components/forms/FirstObservationIn/FirstObservationIn";
import FloatingCurve from "../../pages/structured-products/components/forms/FloatingCurve/FloatingCurve";
import Frequency from "../../pages/structured-products/components/forms/Frequency/Frequency";
import Underlyings from "../../pages/structured-products/components/forms/underlyings/Underlyings";
import { FormValue } from "./types";

// key - defined in custom config in db
// legalShape is value mapped from resolve (graphql does not like hyphens)
export const CUSTOM_OPTIONS = {
  "legal-shapes": "legalShapes",
};

export const getCustomComponentsProps = (
  name: string,
  id: string,
  customOptionsMap: Record<string, ComponentOptions> = {},
  onChange: (evt, value: any) => void,
  formName: string,
  changeField: (formName: string, field: string, value: any) => void,
  validation: string | undefined,
  label: string | undefined,
) => {
  switch (name) {
    case "underlyings": {
      return {
        name: id,
        component: Underlyings,
        total: 1,
        showBottomPadding: false,
        addUnderlyingImmediately: true,
        formName,
        isForConfigurableSP: true,
        onUnderlyingChanged: onChange,
        validation,
        label,
      };
    }
    case "legal-shapes": {
      const key = CUSTOM_OPTIONS[name];

      return {
        name: id,
        component: InlineSelectInput,
        options: customOptionsMap[key],
        onChange,
        clearable: false,
      };
    }
    case "floatingCurve": {
      const optionsMap = customOptionsMap["floatingCurve"];
      return {
        name: id,
        component: FloatingCurve,
        optionsMap,
        formName,
        changeField,
        onChange,
      };
    }
    case "frequency": {
      return {
        name: id,
        component: Frequency,
        formName,
        changeField,
      };
    }
    case "firstObservationIn": {
      return {
        name: id,
        component: FirstObservationIn,
        formName,
        changeField,
      };
    }
    case "issuer": {
      if (!customOptionsMap[name]?.length) {
        return undefined;
      }
      const options = customOptionsMap[name];
      return {
        name: id,
        component: InlineSelectInput,
        options,
        onChange,
        clearable: false,
      };
    }
    default:
      // handle custom data from static service
      if (customOptionsMap[name]) {
        const options = customOptionsMap[name];
        return {
          name: id,
          component: InlineSelectInput,
          options,
          onChange,
          clearable: false,
        };
      }

      return undefined;
  }
};

export type FieldValueMapping = { [field: string]: number | string | FormValue };

interface UpdateValuesParams {
  formUpdate: any;
  fieldName: string;
  formValues: FieldValueMapping;
  rules: Rule[];
  onChangeField: (field: string, value: any) => void;
  setVisible: (visibleMap: Record<string, boolean>) => void;
  setReadOnly: (readOnlyMap: Record<string, boolean>) => void;
  activeRules: Rule[];
  setActiveRules: Dispatch<SetStateAction<UpdateValuesParams["activeRules"]>>;
  defaultVisible: boolean;
  defaultReadOnly: boolean;
}
/**
 *  This regex will match <variable or digit> < +, -, *, / or % > <variable or digit>
 *  e.g.
 *      1 / $payoff>underlying>strike-level
 *      $payoff>underlying>strike-level + 2
 *      $payoff>underlying>strike-level * $payoff>redemption>gearing
 */
export const SIMPLE_ARITHMETIC_REGEX =
  /^((?:\$?\w+(?:-+\w+)*>?)+|\d+)\s*([-+\/*%])\s*((?:\$?\w+(?:-+\w+)*>?)+|\d+)$/;

const getFormField = (value: string) =>
  (value?.charAt(0) === "$" ? value.split("$")?.[1] : value)?.trim();

export const getFormValue = (formField: string | undefined, formValues) => {
  const formValue = formField ? formValues[getFormField(formField)] : undefined;
  const displayValue = formValue?.label || formValue;
  return isDefined(displayValue) && !isNaN(displayValue)
    ? Number(displayValue)
    : displayValue;
};

const getValueForCalculation = (value: string, formValues, fieldName?: string, formUpdate?: string) => {
  const numValue = Number(value);
  const formField = getFormField(value);
  if (isDefined(formUpdate) && formField === fieldName && !isNaN(Number(formUpdate))) {
    return Number(formUpdate);
  }

  return isNaN(numValue) && isDefined(formField)
    ? getFormValue(formField, formValues)
    : numValue;
};

// Match the content inside parentheses after "workday"
const WORKDAY_REGEX = /workday\(([^,]+),([^,]+)\)/;

const handleDateValue = (value: string, formValues) => {
  if (value) {
    if (value.includes("today")) {
      return moment().format("YYYY-MM-DD");
    }

    const matches = value.toLowerCase().match(WORKDAY_REGEX);

    if (value.includes("workday")) {
      if (matches?.length === 3) {
        const [_, startDateField, offsetField] = matches;
        const offset = getFormValue(offsetField, formValues);
        const startDate = getFormValue(startDateField, formValues);
        if (startDate && isDefined(offset) && !isNaN(offset)) {
          return moment(startDate).businessAdd(offset).format("YYYY-MM-DD");
        }
      }
      return null;
    }
  }
  return value;
};

export const getCalculatedValue = (
  value: string,
  formValues: FieldValueMapping,
  fieldName?: string,
  formUpdate?: string,
) => {
  const formValue = getFormValue(value, formValues);
  if(isDefined(formValue)){
    return formValue;
  }
  const parsedValue = value.match(SIMPLE_ARITHMETIC_REGEX);
  if (parsedValue) {
    const number1 = getValueForCalculation(parsedValue[1], formValues, fieldName, formUpdate);
    const operator = parsedValue[2];
    const number2 = getValueForCalculation(parsedValue[3], formValues, fieldName, formUpdate);

    if (!isDefined(number1) || !isDefined(number2)) {
      return value;
    }

    switch (operator) {
      case "+":
        return number1! + number2!;
      case "-":
        return number1! - number2!;
      case "*":
        return number1! * number2!;
      case "%":
        return number1! % number2!;
      case "/":
        if (number2 !== 0) {
          return number1! / number2!;
        }
        return null;
      default:
        return value;
    }
  }

  return handleDateValue(value, formValues);
};

const getValue = (value: string | number | boolean | undefined) => {
  switch (value) {
    case "true":
      return true;
    case "false":
      return false;
    default:
      return value;
  }
};

export const updateValuesWithRules = ({
  formUpdate,
  formValues,
  fieldName,
  rules,
  onChangeField,
  setVisible,
  setReadOnly,
  activeRules,
  setActiveRules,
  defaultVisible,
  defaultReadOnly,
}: UpdateValuesParams) => {
  const visibleMap = {};
  const readOnlyMap = {};

  if (rules.length && onChangeField) {
    const formKeys = Object.keys(formValues);
    const underlyings = formKeys.filter(
      (key) => key.includes("underlying_") && isDefined(formValues[key]),
    );

    /**
     * Note: Rules are run in order, last rule will have priority
     */
    const rulesToRun: Rule[] = [];

    rules.forEach((rule) => {
      const { condition = [] } = rule;

      const hasMetAllConditions = condition.every(({ id, value, hasValue }) => {
        /**
         * special logic to handle reading underlyings custom field
         */
        const isUnderlyingField = id.includes("underlying_");
        const fieldValue = id === fieldName ? formUpdate : formValues[id];
        const formValue = fieldValue?.label || fieldValue;

        if (isUnderlyingField) {
          const index = parseInt(id.split("_")[1]) - 1;
          if (hasValue && isDefined(formValues[underlyings[index]])) {
            return true;
          }

          /**
           * generic logic to check rules
           */
        } else if (hasValue && isDefined(formValue)) {
          return true;
        } else if (isDefined(value) && getValue(value) === getValue(formValue)) {
          return true;
        }
        return false;
      });

      if (hasMetAllConditions) {
        rulesToRun.push(rule);
      }
    });

    /**
     * Need to revert readOnly and visible fields when conditions
     * no longer match
     */
    const rulesToBeReverted = activeRules.filter((r) => {
      return !rulesToRun.includes(r);
    });

    rulesToBeReverted.forEach(({ update }) => {
      update.forEach((u) => {
        if (isDefined(u.visible)) {
          visibleMap[u.id] = defaultVisible;
        }
        if (isDefined(u.readOnly)) {
          readOnlyMap[u.id] = defaultReadOnly;
        }

        if (isDefined(u.value)) {
          onChangeField(u.id, null);
        }
      });
    });

    rulesToRun.forEach(({ update }) => {
      update.forEach((u) => {
        if (isDefined(u.value)) {
          if (typeof u.value === "string") {
            const calculatedValue = getCalculatedValue(u.value, formValues, fieldName, formUpdate);
            onChangeField(u.id, calculatedValue);
          } else {
            onChangeField(u.id, u.value);
          }
        }
        if (isDefined(u.visible)) {
          visibleMap[u.id] = u.visible;
        }
        if (isDefined(u.readOnly)) {
          readOnlyMap[u.id] = u.readOnly;
        }
      });
    });

    setActiveRules(rulesToRun);
  }

  setVisible(visibleMap);
  setReadOnly(readOnlyMap);
};

export const getWrapperLayout = (
  wrapperList: WrapperData[] | undefined,
  selectedWrapper: string | undefined,
) =>
  wrapperList?.find((wrapper) => wrapper.wrapperUri === selectedWrapper)
    ?.layout || [];

export const getStructureLayoutId = (structures, selectedStructure) =>
  structures.find((structure) => structure.value === selectedStructure?.value)
    ?.layoutId;

export const shouldUpdate = (prev, props) => {
  // reduxform has a bug (infinite loop)  where it will re-render too many times
  return JSON.stringify(prev) === JSON.stringify(props);
};
