import React  from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import Bundle from 'pages/Bundle';
import LoadingFullPage from 'components/loading/LoadingFullPage';
import ErrorBoundary from 'components/errorBoundary/ErrorBoundary';

export const PricePage = props => (
  <ErrorBoundary>
    <Bundle title="Minifutures Platform" load={() => import('layouts/MinifutureLayout')}>
      {Component => (Component ? <Component {...props} /> : <LoadingFullPage />)}
    </Bundle>
  </ErrorBoundary>
);

const mapStateToProps = createSelector(
  [state => state.auth],
  ({ account }) => ({
    isConfigurableMf: account?.isConfigurableMf || false,
  })
);

export default compose(
  connect(mapStateToProps),
)(PricePage);
