import jsonLogic from "json-logic-js";
import { isArray } from "lodash";
import validateJs from "utils/validate.js";
import { isDefined } from "validate.js";

const getVars = (numericalityRule: object) => {
  const text = JSON.stringify(numericalityRule);
  const matches = text.match(/(?<="var":(["']))([^"']*)/g);
  const uniqueVars = new Set(matches);
  const uniqueVarsArray = Array.from(uniqueVars);
  return uniqueVarsArray;
};

const parseNumericality = (numericality: object, formData) => {
  const formattedNumericality = {};
  for (const [numericalityKey, numericalityRule] of Object.entries(
    numericality,
  )) {
    if (typeof numericalityRule === "number") {
      formattedNumericality[numericalityKey] = numericalityRule;
    } else {
      const arrayOfVars = getVars(numericalityRule);
      const parsedData = arrayOfVars.reduce((acc, i) => {
        acc[i] = isNaN(formData[i])
          ? formData[i]?.value || formData[i]
          : parseFloat(formData[i]);
        return acc;
      }, {});
      const addedLogic = jsonLogic.apply(numericalityRule, parsedData);
      formattedNumericality[numericalityKey] = isArray(addedLogic)
        ? addedLogic[0]
        : addedLogic;
    }
  }
  return formattedNumericality;
};

const getValue = (formData) =>
  isNaN(formData) ? formData?.value || formData : parseFloat(formData);

const getStringComparison = (equalTo, formData, key) => {
  const fields = isArray(equalTo) ? equalTo : [equalTo];
  const imcomparibleFields = fields.filter(
    (i) =>
      isDefined(formData[i]) &&
      getValue(formData[i]) !== getValue(formData[key]),
  );
  return imcomparibleFields.length ? imcomparibleFields : undefined;
};

const convertIfBoolean = (value) =>
  typeof value === "boolean" ? `${value}` : value;

const parseRules = (rules: object, formData = {}) => {
  const formattedRules = {};
  for (const [key, rule] of Object.entries(rules)) {
    if (rule.hasOwnProperty("numericality")) {
      const { numericality } = rule;
      formattedRules[key] = {
        ...rule,
        numericality: parseNumericality(numericality, formData),
      };
    } else if (rule.compareTo) {
      const { compareTo, ...restRule } = rule;
      const validateName =
        rule.compareTo.datatype === "date" ? "compareToDate" : "compareTo";
      formattedRules[key] = {
        ...restRule,
        [validateName]: {
          ...rule.compareTo,
          formData,
        },
      };
    } else if (rule.stringComparison) {
      const { equalTo } = rule.stringComparison;
      if (equalTo) {
        formattedRules[key] = {
          ...rule,
          stringComparison: getStringComparison(equalTo, formData, key),
        };
      }
    } else if (rule.conditions) {
      const { conditions, ...restRule } = rule;
      const activeCondition = conditions.find(
        ({ id, value }) =>
          convertIfBoolean(value) === getValue(convertIfBoolean(formData[id])),
      );
      if (activeCondition) {
        const { equalTo, compareTo, permittedValues } =
          activeCondition.validate;
        if (equalTo) {
          formattedRules[key] = {
            ...restRule,
            stringComparison: getStringComparison(equalTo, formData, key),
          };
        } else if (compareTo) {
          formattedRules[key] = {
            ...restRule,
            compareTo: {
              ...compareTo,
              formData,
            },
          };
        } else if (permittedValues) {
          formattedRules[key] = {
            ...restRule,
            permittedValues,
          };
        }
      }
    } else {
      formattedRules[key] = {
        ...rule,
      };
    }
  }
  return formattedRules;
};

export default function validate(rules: object) {
  return function (formData, props, name) {
    const parsedRules = parseRules(rules, props.values || props);

    if (name) {
      // validator is being called a second time without form values, we can skip this call
      return;
    }

    return validateJs(formData, parsedRules) || {};
  };
}
